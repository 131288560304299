<!--
 * @Author: yuwenwen
 * @Date: 2022-11-10 17:23:46
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-02-07 16:01:49
 * @FilePath: \nc-funeral-mobile\src\views\products\productDetails.vue
-->
<template>
  <div class="page-container product-details-container">
    <!-- 商品图片轮播 -->
    <van-swipe v-if="productList.length > 0">
      <van-swipe-item
        v-for="(item, index) in imgList"
        :key="index"
      >
        <img class="swiper-img" fit="cover" :src="item" />
      </van-swipe-item>
      <template #indicator="{ active, total }">
        <div class="custom-indicator">{{ active + 1 }}/{{ total }}</div>
      </template>
    </van-swipe>

    <!-- 商品名称、库存、价格 -->
    <div class="price-name-stock" v-if="productList.length > 0">
      <div class="name-stock">
        <div class="name">{{ productInfo.chargeName }}</div>
        <div class="stock">
          库存{{ productInfo.productExtend.surplusStock }}
        </div>
      </div>
      <div class="price"><span>¥</span> {{ productInfo.salesPrice }}</div>
    </div>
    <!-- 商品介绍 -->

    <div class="product-introduce" v-if="productList.length > 0">
      <div class="title">
        <div class="introduce-title">商品介绍</div>
      </div>
      <!-- 商品介绍 -->
      <div class="product-details-html" v-html="productInfo.productExtend.details"></div>
    </div>

    <!-- 操作栏 -->
    <div class="operation-box" @click.stop="toProductDetailsPage">
      <div >
        <van-badge :content="shopcartNum" max="99">
          <img class="img" src="../../assets/images/shop-cart-black.png" />
        </van-badge>
      </div>
      <div class="btn" @click.stop="addToShopcart">加入购物车</div>
    </div>
  </div>
</template>
<script>
import { getProductDetails } from "@/api/product.js";
import { getImagesUrl } from "@/utils/funeral.js";
import { showToast} from "vant";
export default {
  name: "ProductDetails",
  data() {
    return {
      productId: "",
      productInfo: {},
      productList: [],
      imgList:[],
    };
  },
  created() {
    let { id } = this.$route.query;
    this.productId = id;
    this.getProductDetails();
  },
  computed: {
    shopcartNum: {
      get() {
        return this.$store.state.product.shopcartNum;
      },
      set(val) {
        return val;
      },
    },
    shopCartList: {
      get() {
        return this.$store.state.product.shopCartList;
      },
    },
  },
  mounted() {},
  methods: {
    // 获取商品详情
    async getProductDetails() {
      let [err, res] = await this.$awaitWrap(getProductDetails(this.productId));
      if (err) {
        return;
      }
      this.imgList = res.data.productExtend.picture.split(',');
      this.imgList =  this.imgList.map((item)=>{
        if(item){
           item = getImagesUrl(item);
           return item;
        }
      })
      this.productInfo = res.data;
      this.productInfo.cartNum = 1;
      this.productInfo.picture = this.imgList[0];
      this.productList = [res.data];
      console.log(res);
    },
    // 加入购物车
    addToShopcart() {
      
      let item = this.productInfo;
      
      let storeList = this.shopCartList;
      if (storeList.length > 0) {
        let bool = storeList.some((rec) => {
          return rec.priceId == item.priceId;
        });
        if (bool) {
          storeList.forEach((product) => {
            if (product.priceId == item.priceId) {
              if (product.cartNum >= item.productExtend.surplusStock) {
                showToast("该商品已全部加入购物车，没有剩余库存");
                return;
              } else {
                product.cartNum = product.cartNum + 1;
                showToast("加入购物车成功，请前去购物车查看");
              }
            }
          });
        } else {
          console.log('2');
          showToast("加入购物车成功，请前去购物车查看");
          storeList.push(item);
        }
      } else {
        console.log('3');
        showToast("加入购物车成功，请前去购物车查看");
        storeList.push(item);
      }
      this.$store.dispatch("setShopCartList", storeList);
    },

    // 跳转到购物车
    toProductDetailsPage() {
      console.log('333')
      this.$router.push({ path:'/product/cart',})
    },
  },
};
</script>
<style lang="less" scoped>

@import "../../assets/styles/product.less";

</style>

<style>
.product-details-html p img{
  width: 100% !important;
  height: auto !important; 
}
.product-details-html ol{
  list-style: decimal !important;
  padding: 10px !important;
  margin: 10px !important;
}

.product-details-html ul{
  list-style: disc !important;
  padding: 10px !important;
  margin: 10px !important;
}
</style>